// assets
import { IconPaperBag, IconSettings, IconUserPlus } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const config = {
    id: 'config',
    title: 'Configuration',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'configs',
            title: 'Mes configuration',
            type: 'collapse',
            icon: IconSettings,

            children: [
                {
                    id: 'utisateur',
                    title: 'Mes utilisateurs',
                    type: 'item',
                    url: '/utilisateur/liste', 
                    target: false,
                    icon: IconUserPlus,
                },
                {
                    id: 'poste',
                    title: 'Mes Postes',
                    type: 'item',
                    url: '/poste/liste',
                    target: false,
                    icon: IconPaperBag,
                },
                    {
                        id: 'employes',
                        title: 'Mes employés',
                        type: 'item',
                        url: '/employe/liste',
                        icon: IconUserPlus,
                        // breadcrumbs: false,
                        target: false
                    }
                
            ]
        }
    ]
};

export default config;
