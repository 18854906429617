import CryptoJS from 'crypto-js';

let basename = ''; ///web';
const secretPass = 'XkhZG4fW2t2W';
let USERNAME;
let USERID;
let CONTACT;
let EMAIL;
let ACCES_TOKEN;
let USER_DROIT;
const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
    return data;
};
if (localStorage.getItem('user-info-gal')) {
    var descrypt = decryptData(localStorage.getItem('user-info-gal'));
    USERNAME = descrypt.user_data.name;
    USERID = descrypt.user_data.id;
    CONTACT  = descrypt.user_data.contact;
    EMAIL = descrypt.user_data.emailbis;
    ACCES_TOKEN = descrypt.access_token;
    USER_DROIT = descrypt.user_data.listdroituser ? descrypt.user_data.listdroituser : [];
}
const config = {
    username: USERNAME,
    iduser: USERID,
    access_token: ACCES_TOKEN,
    contact: CONTACT,
    emailbis: EMAIL,
    userdroit: USER_DROIT,
    basename: basename,
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,
    tok: {
        headers: {Authorization: `Bearer ${ACCES_TOKEN}`}
    },
    //ipapi: 'http://127.0.0.1:8000',
    ipapi: 'https://apigpc.tracky-entrepot.com',
    nomcouleur :['BLEU','VIOLET','ROSE','ROUGE','ORANGE','JAUNE','VERT','NOIR','MARRON','GRIS','BLANC','BEIGE','ARGENT'],
    codecouleur : ['#004DCF','#9900EF','#F78DA7','#F44336','#FF9800','#FFEB3B','#4CAF50','#000','#795548','#555B61','#FFF','#C8AD7F','#C0C0C0'],
    search: function search(obj, key) {

        if (typeof obj !== 'object' || obj === null) {
            return obj === key ? obj : undefined;
        }
        for (const [k, v] of Object.entries(obj)) {
            const result = search(v, key);
            if (result !== undefined) {
                return result;
            }
        }
        return undefined;
    }
};

export default config;
