// assets
import { IconUser, IconMoodKid, IconAlignCenter, IconCash, IconUserPlus, IconCalendar } from '@tabler/icons';

// constant
// const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const scheduler = {
    id: 'agenda',
    title: 'Mon agenda',
    type: 'group',
    children: [
        {
            id: 'agendas',
            title: 'Planning commerciaux',
            type: 'item',
            url: '/scheduler/liste',
            icon: IconCalendar,
            breadcrumbs: false
        }
    ]
};

export default scheduler;
