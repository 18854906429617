import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Prospects = Loadable(lazy(() => import('views/gestvente/prospect/prospect')));
const Vehicules = Loadable(lazy(() => import('views/gestvente/stocks/voiture/vehicule')));
const Factures = Loadable(lazy(() => import('views/gestvente/facture/facture')));
const Schedulers = Loadable(lazy(() => import('views/scheduler/scheduler')));
const Users = Loadable(lazy(() => import('views/utilisateurs/utilisateur')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Postes = Loadable(lazy(() => import('views/postes/poste')));
// employé routing
const Employes = Loadable(lazy(() => import('views/employe/employe')));


// const Salaires = Loadable(lazy(() => import('views/salaires/salaire')));

// const Rentres = Loadable(lazy(() => import('views/rentres/rentree')));

// const Reglements = Loadable(lazy(() => import('views/reglement/reglement')));

// dashboard routing


// // pensionnaire routing
// const Enfants = Loadable(lazy(() => import('views/pensionnaires/enfant/enfant')));
// const FicheEnfants = Loadable(lazy(() => import('views/pensionnaires/enfant/enfantfiche')));
// const Parents = Loadable(lazy(() => import('views/pensionnaires/parent/parent')));

// // cpontrat routing
// const Contrats = Loadable(lazy(() => import('views/contrats/contrats')));

// // employé routing
// const Depenses = Loadable(lazy(() => import('views/depenses/depense/depense')));
// const TypeDepense = Loadable(lazy(() => import('views/depenses/typedepense/typeDepense')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        // {
        //     path: 'pensionnaire',
        //     children: [
        //         {
        //             path: 'parent',
        //             element: <Parents />
        //         },
        //         {
        //             path: 'enfant',
        //             element: <Enfants />
        //         },
        //         {
        //             path: 'enfant/:id',
        //             element: <FicheEnfants />
        //         },
        //         {
        //             path: 'contrat',
        //             element: <Contrats />
        //         },
        //         {
        //             path: 'reglement-contrat',
        //             element: <Reglements />
        //         }
        //     ]
        // },
        {
            path: 'employe',
            children: [
                {
                    path: 'liste',
                    element: <Employes />
                }
            ]
        },
        {
            path: 'scheduler',
            children: [
                {
                    path: 'liste',
                    element: <Schedulers />
                }
            ]
        },
        {
            path: 'gestvente',
            children: [
                {
                    path: 'prospects',
                    element: <Prospects />
                },
                {
                    path: 'vehicules',
                    element: <Vehicules />
                },
                {
                    path: 'factures',
                    element: <Factures />
                }
            ]
        },
        // {
        //     path: 'depense',
        //     children: [
        //         {
        //             path: 'salaire',
        //             element: <Salaires />
        //         }
        //     ]
        // },
        // {
        //     path: 'rentree',
        //     children: [
        //         {
        //             path: 'liste',
        //             element: <Rentres />
        //         }
        //     ]
        // },
        {
            path: 'utilisateur',
            children: [
                {
                    path: 'liste',
                    element: <Users />
                }
            ]
        },
        {
            path: 'poste',
            children: [
                {
                    path: 'liste',
                    element: <Postes />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
