// assets
import { IconBox, IconCash, IconCashBanknote, IconCashOff, IconEye, IconHeading } from '@tabler/icons';

const gestvente = {
    id: 'vente',
    title: 'Gestion des ventes',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'dep',
            title: 'Voir +',
            type: 'collapse',
            icon: IconEye,

            children: [
                {
                    id: 'prospect',
                    title: 'Mes prospects',
                    type: 'item',
                    url: '/gestvente/prospects',
                    icon: IconHeading,
                    breadcrumbs: false
                },
            {
                id: 'stock',
                title: 'Mon stock véhicule',
                type: 'item',
                url: '/gestvente/vehicules',
                icon: IconBox,
                breadcrumbs: false
            }, {
                id: 'facture',
                title: 'Factures',
                type: 'item',
                url: '/gestvente/factures',
                icon: IconCash,
                breadcrumbs: false
            },
           
            ]
           
        },
       
    ]
};

export default gestvente;
