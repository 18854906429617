import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';
// import employe from './employes';
// import pensionnaire from './pensionnaire';
// import charges from './charges';
import gestvente from './gestvente';
import config from './config';
import scheduler from './scheduler';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard,scheduler, gestvente, config] //, pages, utilities, other]
};

export default menuItems;
